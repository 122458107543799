import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logoDefault from "./images/general/logo-hor.svg";
import logoTransparent from "./images/general/logo-white-hor.svg";

import Helmet from 'react-helmet'

const Header = ({ siteTitle }) => (

  <nav className="ui-transparent-nav navbar navbar-fixed-top transparent navbar-light bg-white">
    <Helmet>
      <body className="ui-transparent-nav" />
    </Helmet>
    <div className="container">

      <Link to="/" className="ui-variable-logo navbar-brand">
        <img className="logo-default" src={logoDefault}
             alt={siteTitle} data-uhd/>
        <img className="logo-transparent" src={logoTransparent}
             alt={siteTitle} data-uhd/>
      </Link>


      <div className="ui-navigation navbar-right">
        <div id="menu">
          <input id="toggle-menu" type="checkbox"></input>
          <label htmlFor="toggle-menu" className="hamburger">
            <div className="top-bun"></div>
            <div className="meat"></div>
            <div className="bottom-bun"></div>
          </label>
          <ul className="nav navbar-nav">
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>
              <Link to="/sobre" data-toggle="dropdown">Sobre</Link>
            </li>
            <li>
              <Link to="/servicos" data-toggle="dropdown">Serviços</Link>
            </li>
            <li>
              <Link to="/solucoes" data-toggle="dropdown">Soluções</Link>
            </li>
            <li>
              <Link to="contato">Contato</Link>
            </li>
          </ul>
        </div>
      </div>



    </div>
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
