import React from 'react'
import Link from 'gatsby-link'

const Footer = () => (

  <footer className="ui-footer bg-sc">
    {/*<div className="container pt-6 pb-6">
      <div className="row">
        <div className="col-md-4 col-sm-6 footer-about footer-col center-on-sm">
          <img src="assets/img/logo/applify-logo-white@2x.png" data-uhd="" alt="Applif - App Landing HTML Template"/>
          <p className="mt-1">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Donec elementum ligula eu sapien consequat eleifend.
          </p>
        </div>
        <div className="col-md-2 col-6 footer-col">
          <h6 className="heading footer-heading">Quick Nav</h6>
          <ul className="footer-nav">
            <li>
              <a href="demo-mobile-app-1.html">Mobile App 1</a>
            </li>
            <li>
              <a href="demo-mobile-app-2.html">Mobile App 2</a>
            </li>
            <li>
              <a href="demo-saas.html">Mobile Saas</a>
            </li>
            <li>
              <a href="demo-web-app.html">Web App</a>
            </li>
            <li>
              <a href="index.html#demos">More Demos</a>
            </li>
          </ul>
        </div>
        <div className="col-md-2 col-6 footer-col">
          <h6 className="heading footer-heading">Other Pages</h6>
          <ul className="footer-nav">
            <li>
              <a href="page-api-docs.html">API Docs</a>
            </li>
            <li>
              <a href="page-pricing.html">Pricing Page</a>
            </li>
            <li>
              <a href="page-contact.html">Contact page</a>
            </li>
            <li>
              <a href="page-blog-grid.html">Blog Grid</a>
            </li>
            <li>
              <a href="page-coming-soon.html">Comig Soon</a>
            </li>
            <li>
              <a href="page-404.html">404 Error</a>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-6 footer-col center-on-sm">
          <h6 className="heading footer-heading">Newsletter</h6>
          <form autoComplete="on" >
            <div className="form-group">
              <div className="input-group">
                <input autoComplete="email" className="input form-control" name="email" placeholder="Email"/>
                <div className="input-group-append">
                  <button className="btn ui-gradient-peach">Subscribe <span className="fa fa-send"></span></button>
                </div>
              </div>
            </div>
          </form>
          <div>
            <a className="btn btn-circle shadow-md">
              <span className="fa fa-facebook"></span>
            </a>
            <a className="btn btn-circle shadow-md">
              <span className="fa fa-linkedin"></span>
            </a>
          </div>
        </div>
      </div>
    </div>*/}

    <div className="footer-copyright bg-dark-sc">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 center-on-sm">
            <p>
              © {new Date().getFullYear()}, <strong>System Code</strong>. Todos os direitos reservados.
            </p>
          </div>
          <div className="col-sm-6 text-right">
            <ul className="footer-nav">
              <li>
                <a href="https://www.facebook.com/systemcode.com.br" className="btn btn-circle shadow-md" target="_BLANK">
                  <span className="fa fa-facebook"></span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/systemcode/" className="btn btn-circle shadow-md" target="_BLANK">
                  <span className="fa fa-linkedin"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </footer>

);

export default Footer
